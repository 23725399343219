import httpClient from "@/client";

export default {
    get: {
        timeframes: () => {
            return httpClient.get('/api/account/timeframes');
        },
        locationsTopStats: timeframe_id => {
            return httpClient.get('/api/account/locations/stats/top?timeframe_id=' + timeframe_id);
        },
        locationsData: timeframe_id => {
            return httpClient.get('/api/account/locations/data?timeframe_id=' + timeframe_id);
        },
        locationsDataRefetch: timeframe_id => {
            return httpClient.get('/api/account/locations/data/refetch?timeframe_id=' + timeframe_id);
        },
        keyPerformanceIndicators: () => {
            return httpClient.get('/api/account/kpi');
        }
    },
    post: {
        createKPI: (name, rules, account_id) => {
            return httpClient.post('/api/account/kpi', {
                name,
                is_active: true,
                account_id,
                rules
            })
        }
    },
    put: {
        updateKPI: updatedKPI => {
            return httpClient.put(`/api/account/kpi/${updatedKPI.id}`, {
                ...updatedKPI
            });
        }
    },
    delete: {
        deleteKpi: kpiId => {
            return httpClient.delete(`/api/account/kpi/${kpiId}`);
        }
    }
};
