import _ from 'lodash';
import DashboardService from "@/services/DashboardService";

export default {
    namespaced: true,
    state: {
        topStatsLoading: true,
        topStats: {
            top_leads: _.times(3, () => ({})),
            top_appointments: _.times(3, () => ({})),
            top_revenue: _.times(3, () => ({}))
        },

        timeframes: [],
        timeframeOptions: [],
        currentTimeframe: null,

        locationsDataLoading: true,
        locationsData: _.times(6, () => ({})),
        visibleLocationsTableColumns: [],

        keyPerformanceIndicators: [],
        editedKpi: null,
    },
    mutations: {
        SET_TIMEFRAMES: (state, timeframes) => {
            state.timeframes = timeframes;
        },
        SET_TIMEFRAME_OPTIONS: (state, timeframeOptions) => {
            state.timeframeOptions = timeframeOptions;
        },
        SET_CURRENT_TIMEFRAME: (state, timeframe) => {
            state.currentTimeframe = timeframe;
        },
        SET_CURRENT_TIMEFRAME_BY_ID: (state, timeframeID) => {
            if (!state.timeframes.length) return;
            state.currentTimeframe = state.timeframes.find(({id}) => id === timeframeID);
        },
        SET_TOP_STATS_LOADING: (state, loading) => {
            state.topStatsLoading = loading;
        },
        SET_TOP_STATS: (state, topStats) => {
            state.topStats = topStats;
        },
        SET_LOCATIONS_DATA_LOADING: (state, loading) => {
            state.locationsDataLoading = loading;
        },
        SET_LOCATIONS_DATA: (state, locationsData) => {
            state.locationsData = locationsData;
        },
        SET_VISIBLE_LOCATIONS_TABLE_COLUMNS: (state, visibleLocationsTableColumns) => {
            state.visibleLocationsTableColumns = visibleLocationsTableColumns;
            localStorage.setItem('dashboard_visible_columns', JSON.stringify(visibleLocationsTableColumns));
        },
        SET_KEY_PERFORMANCE_INDICATORS: (state, keyPerformanceIndicators) => {
            state.keyPerformanceIndicators = keyPerformanceIndicators;
        },
        SET_EDITED_KPI: (state, kpi) => {
            state.editedKpi = kpi;
        }
    },
    actions: {
        fetchTimeframes: async ({commit}) => {
            commit('SET_TIMEFRAME_OPTIONS', [{value: null, text: 'Loading...'}]);

            const {data: {timeframes}} = await DashboardService.get.timeframes();

            const timeframeOptions = timeframes.map(({id: value, name: text}) => ({value, text}));
            const thirtyDaysTimeframe = timeframes.find(timeframe => {
                return +timeframe.range_days_start === 0 && +timeframe.range_days_end === 30;
            });

            commit('SET_TIMEFRAMES', timeframes);
            commit('SET_TIMEFRAME_OPTIONS', timeframeOptions);
            commit('SET_CURRENT_TIMEFRAME', thirtyDaysTimeframe);
        },
        fetchTopStats: async ({commit, state}) => {
            if (!state.currentTimeframe) {
                throw new Error('Timeframe must be selected first.');
            }

            commit('SET_TOP_STATS_LOADING', true);
            const {data: {stats}} = await DashboardService.get.locationsTopStats(state.currentTimeframe.id);
            commit('SET_TOP_STATS', stats);
            commit('SET_TOP_STATS_LOADING', false);
        },
        fetchLocationsData: async ({commit, state}) => {
            if (!state.currentTimeframe) {
                throw new Error('Timeframe must be selected first.');
            }

            commit('SET_LOCATIONS_DATA_LOADING', true);
            const {data: {locations_data}} = await DashboardService.get.locationsData(state.currentTimeframe.id);
            commit('SET_LOCATIONS_DATA', Object.values(locations_data));
            commit('SET_LOCATIONS_DATA_LOADING', false);
        },
        refetchLocationsData: async ({commit, state}) => {
            if (!state.currentTimeframe) {
                throw new Error('Timeframe must be selected first.');
            }

            commit('SET_LOCATIONS_DATA_LOADING', true);
            const {data: {locations_data}} = await DashboardService.get.locationsDataRefetch(state.currentTimeframe.id);
            commit('SET_LOCATIONS_DATA', Object.values(locations_data));
            commit('SET_LOCATIONS_DATA_LOADING', false);
        },
        fetchKeyPerformanceIndicators: async ({commit}) => {
            const {data: {kpis}} = await DashboardService.get.keyPerformanceIndicators();
            commit('SET_KEY_PERFORMANCE_INDICATORS', kpis);
        },
        createKPI: async ({commit, state}, {name, rules, relatedAccountId}) => {
            const {data: {kpi}} = await DashboardService.post.createKPI(name, rules, relatedAccountId);
            commit('SET_KEY_PERFORMANCE_INDICATORS', [...state.keyPerformanceIndicators, kpi]);
        },
        updateKPI: async ({commit, state}, updatedKPI) => {
            const {data: {kpi}} = await DashboardService.put.updateKPI(updatedKPI);
            const updatedKPIs = state.keyPerformanceIndicators.map(kpi => {
                if (kpi.id === updatedKPI) return updatedKPI;
                return kpi;
            });
            commit('SET_KEY_PERFORMANCE_INDICATORS', updatedKPIs);
        },
        deleteKPI: async ({commit, state}, kpiId) => {
            await DashboardService.delete.deleteKpi(kpiId);
            const updatedKPIs = state.keyPerformanceIndicators.filter(kpi => {
                return kpi.id !== kpiId;
            });
            commit('SET_KEY_PERFORMANCE_INDICATORS', updatedKPIs);
        }
    },
};
