import store from '@/store';

export const auth = (to, from, next) => {
    const routeRequiresAuth = to.matched.some(match => {
        return match.meta.guards && match.meta.guards.includes('auth');
    });

    if (routeRequiresAuth && !store.getters['auth/isAuth']) {
        return next({ name: 'auth.login' });
    }

    if (routeRequiresAuth && !store.getters['auth/hasActiveSubscription'] && to.name !== 'auth.setup-payments') {
        return next({ name: 'auth.setup-payments' });
    }

    if (routeRequiresAuth && store.getters['auth/hasActiveSubscription'] && !store.getters['auth/hasSetupApiKey'] && to.name !== 'auth.setup-api-key') {
        return next({ name: 'auth.setup-api-key' });
    }

    if (routeRequiresAuth && store.getters['auth/hasActiveSubscription'] && store.getters['auth/hasSetupApiKey'] && !store.getters['auth/hasConfiguredLocations'] && to.name !== 'auth.setup-locations') {
        return next({ name: 'auth.setup-locations' });
    }

    next();
};

export const guest = (to, from, next) => {
    const routeRequiresGuest = to.matched.some(match => {
        return match.meta.guards && match.meta.guards.includes('guest');
    });

    if (routeRequiresGuest && store.getters['auth/isAuth']) {
        next('/');
        return;
    }

    next();
};

export const paymentNotSetup = (to, from, next) => {
    const routeRequiresPaymentNotSetup = to.matched.some(match => {
        return match.meta.guards && match.meta.guards.includes('payment-not-setup');
    });

    if (routeRequiresPaymentNotSetup && store.getters['auth/hasActiveSubscription']) {
        return next('/');
    }

    next();
};

export const apiKeyNotSetup = (to, from, next) => {
    const routeRequiresApiKeyNotSetup = to.matched.some(match => {
        return match.meta.guards && match.meta.guards.includes('api-key-not-setup');
    });

    if (routeRequiresApiKeyNotSetup && store.getters['auth/hasSetupApiKey']) {
        return next('/');
    }

    next();
};

export const locationsNotSetup = (to, from, next) => {
    const routerRequiresLocationsNotSetup = to.matched.some(match => {
        return match.meta.guards && match.meta.guards.includes('locations-not-setup');
    });

    if (routerRequiresLocationsNotSetup && store.getters['auth/hasConfiguredLocations']) {
        return next('/');
    }

    next();
};

export const registerRouteGuards = router => {
    router.beforeEach(auth);
    router.beforeEach(guest);
    router.beforeEach(paymentNotSetup);
    router.beforeEach(apiKeyNotSetup);
    router.beforeEach(locationsNotSetup);
};
