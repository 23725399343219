import Vue from 'vue';
import VueRouter from 'vue-router';
import {registerRouteGuards} from '../guards';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: () => import('@/views/front/Dashboard.vue'),
            meta: {
                guards: ['auth']
            },
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('@/views/front/SettingsLayout.vue'),
            children: [
                {
                    path: 'locations',
                    name: 'settings.locations',
                    component: () => import('@/views/front/Settings/Locations.vue'),
                    meta: {
                        guards: ['auth']
                    },
                },
                {
                    path: 'billing',
                    name: 'settings.billing',
                    component: () => import('@/views/front/Settings/Billing.vue'),
                    meta: {
                        guards: ['auth']
                    },
                }
            ]
        },
        {
            path: '/admin/demo-page',
            name: 'admin.demo-page',
            component: () => import('@/views/admin/DemoPage.vue'),
            meta: {
                pageTitle: 'Demo Page',
                breadcrumb: [
                    {
                        text: 'Demo Page',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/auth',
            name: 'auth',
            component: () => import ('@/views/auth/AuthLayout.vue'),
            children: [
                {
                    path: 'login',
                    name: 'auth.login',
                    component: () => import('@/views/auth/Login.vue'),
                    meta: {
                        layout: 'full',
                    },
                },
                {
                    path: 'register',
                    name: 'auth.register',
                    component: () => import('@/views/auth/Register.vue'),
                    meta: {
                        layout: 'full',
                    },
                },
                {
                    path: 'setup/payments',
                    name: 'auth.setup-payments',
                    component: () => import('@/views/auth/SetupPayments.vue'),
                    meta: {
                        layout: 'full',
                        guards: ['auth', 'payment-not-setup']
                    }
                },
                {
                    path: 'setup/api-key',
                    name: 'auth.setup-api-key',
                    component: () => import('@/views/auth/SetupApiKey.vue'),
                    meta: {
                        layout: 'full',
                        guards: ['auth', 'api-key-not-setup']
                    }
                },
                {
                    path: 'setup/locations',
                    name: 'auth.setup-locations',
                    component: () => import('@/views/auth/SetupLocations.vue'),
                    meta: {
                        layout: 'full',
                        guards: ['auth', 'locations-not-setup']
                    }
                },
                {
                    path: 'setup/account',
                    name: 'auth.setup-account',
                    component: () => import('@/views/auth/SetupAccount.vue'),
                    meta: {
                        layout: 'full',
                        guards: ['auth']
                    }
                },
            ]
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
});

registerRouteGuards(router);

export default router;
