<template>
    <b-modal
        id="locations-modal"
        centered
        hide-footer
        size="md"
        title="Location Settings"
        @shown="onModalShown"
    >
        <div>
            <select-locations-box
                class="my-0"
                :loading="locationsLoading"
                :locations="displayedLocations"
                v-model="locationsSearch"
                @select-location="selectLocation"
            />

            <b-button
                @click.prevent="setupLocations"
                variant="primary"
                class="w-100 submit-form-btn mt-2"
                :disabled="loading || !selectedLocations.length"
            >
                <template v-if="loading">
                    <b-spinner small class="mr-50"/>
                    <span class="align-middle">Loading</span>
                </template>
                <span v-else>Set Locations</span>
            </b-button>
        </div>
    </b-modal>
</template>

<script>
import {BModal, BButton, BSpinner} from 'bootstrap-vue';
import toastsMixin from "@/mixins/toastsMixin";
import SelectLocationsBox from "@/layouts/components/auth/SelectLocationsBox";
import LocationsService from "@/services/LocationsService";

export default {
    name: 'LocationsModal',
    components: {
        BModal,
        BButton,
        BSpinner,
        SelectLocationsBox
    },
    mixins: [toastsMixin],
    data() {
        return {
            loading: false,
            locationsLoading: true,
            locations: [],
            displayedLocations: [],
            locationsSearch: '',
        }
    },
    computed: {
        selectedLocations() {
            return this.locations.filter(location => location.selected);
        }
    },
    watch: {
        locationsSearch() {
            this.displayedLocations = this.locations.filter(location => {
                return location.name.toLowerCase().includes(this.locationsSearch.toLowerCase());
            });
        }
    },
    methods: {
        onModalShown() {
            this.fetchLocations();
        },
        async fetchLocations() {
            this.locationsLoading = true;
            const { data: { locations } } = await LocationsService.get.locations()
            this.locations = locations
                .map(location => ({ ...location, selected: !!location.active }))
                .sort(this.sortLocationCompareFunc);
            this.displayedLocations = this.locations;
            this.locationsLoading = false;
        },
        sortLocationCompareFunc: (a, b) => {
            if (a.selected > b.selected) return -1;
            if (a.selected < b.selected) return 1;
            return 0;
        },
        selectLocation(location) {
            location.selected = !location.selected;
            this.locations.sort(this.sortLocationCompareFunc);
            this.displayedLocations.sort(this.sortLocationCompareFunc);
        },
        async setupLocations() {
            try {
                this.loading = true;
                const selectedLocationIds = this.selectedLocations.map(location => location.id);

                const {
                    data: {
                        estimated_time_mins,
                        steps
                    }
                } = await LocationsService.post.saveLocations(selectedLocationIds);

                await this.$store.dispatch('auth/authorizeFromLocalJWT');
                this.loading = false;
                this.showSuccessToast('Locations setup successfully!');
                return this.$router.push({ name: 'auth.setup-account', params: { time: estimated_time_mins } });
            } catch (err) {
                console.log(err);
                this.loading = false;
                this.showErrorToast('Something went wrong.');
            }
        }
    },
}
</script>

<style lang="scss">
#locations-modal {
    .btn.submit-form-btn {
        padding: 16px 28px;
        border-radius: 8px;
    }
}
</style>
