<template>
  <div class="front-layout">
    <locations-modal/>
    <layout-navbar/>
    <setup-steps-locations-modal></setup-steps-locations-modal>

    <div class="layout-content">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import LayoutNavbar from "@/layouts/components/front/LayoutNavbar";
import LocationsModal from "@/layouts/components/front/LocationsModal";
import SetupStepsLocationsModal from "@/layouts/components/front/settings/SetupStepsLocationsModal.vue";

export default {
  name: 'FrontLayout',
  components: {
    LayoutNavbar,
    SetupStepsLocationsModal,
    LocationsModal,
  },
  computed: {
    routesWithoutContainer: () => (['settings'])
  },
  methods: {
    async checkIfAccountIsReady() {
      if (!this.$store.state.auth.user) {
        return;
      }
      const response = await this.$http_client.get('/api/account/setup/time');
      if (response.data.time > 0) {
        this.$bvModal.show('setup-steps-locations-modal');
      }
    }
  },
  mounted() {
    this.checkIfAccountIsReady();
  }
}
</script>
