<template>
  <div class="auth-form-left">
    <div class="site-logo-wrapper">
      <img src="/images/logo.png" alt="site-logo">
    </div>
    <div class="illustration-wrapper">
      <img src="/images/laptop.png" alt="laptop-illustration">
    </div>
    <div class="px-2 px-lg-3 mb-lg-3">
      <div class="form-left-heading">The Ultimate GHL Reporting Dashboard</div>
      <div class="form-left-subheading">
        Real-time agency reporting for all
        your GHL locations. Get setup in 5
        minutes or less.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthCardLeft'
}
</script>
