import httpClient from "@/client";

export default {
    get: {
        locations: () => {
            return httpClient.get('/api/account/locations');
        }
    },
    post: {
        saveLocations: locations => {
            return httpClient.post('/api/account/locations', { locations });
        }
    }
};
