<template>
    <div class="locations-box">
        <b-form-input
            class="location-search"
            @input="val => $emit('input', val)"
            placeholder="Search by location name"
        />

        <transition-group name="fade" tag="div" class="locations-list hide-scrollbar">
            <div v-if="loading" key="locations-loader" class="locations-loading-spinner">
                <b-spinner variant="primary"/>
            </div>

            <div
                v-else
                v-for="location in locations"
                :key="location.id"
                @click="$emit('select-location', location)"
                class="location"
            >
                <div class="location-name-and-initials-wrapper">
                    <div class="location-initials">
                        {{ location.name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substr(0, 1) }}
                    </div>
                    <div class="location-name"> {{ location.name }}</div>
                </div>
                <div class="location-check-wrapper">
                    <svg v-if="location.selected" xmlns="http://www.w3.org/2000/svg" fill="#4bde80"
                         width="20"
                         height="20"
                         viewBox="0 0 20 20"
                    >
                        <path
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" width="20" height="20"
                         viewBox="0 0 24 24"
                         stroke="#d1d5dc" stroke-width="2">
                        <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                    </svg>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import {BSpinner, BFormInput} from "bootstrap-vue";

export default {
    name: 'SelectLocationsBox',
    components: {
        BSpinner,
        BFormInput
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        locations: {
            type: Array,
            required: true
        }
    },
}
</script>

<style lang="scss">

.locations-box {
    margin-top: 28px;
    margin-bottom: 28px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    position: relative;

    .form-control {
        background: #f3f4f6;
        border-color: #f3f4f6;
        color: #9aa3ae;
        border-radius: 8px;
        padding: 16px;
        height: auto;
        font-size: 16px;
        box-shadow: none !important;

        &.is-invalid {
            background-position: right 16px center;
            padding-right: 48px;
            border-color: #ea5455;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
        }
    }

    .locations-loading-spinner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner-border {
            width: 56px;
            height: 56px;
        }
    }

    .form-control.location-search {
        border-radius: 12px 12px 0 0;
    }

    .locations-list {
        height: 360px;
        overflow-y: scroll;
    }

    .location {
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e5e7eb;
        cursor: pointer;

        &:hover {
            background-color: rgb(249, 250, 251);
        }

        .location-name-and-initials-wrapper {
            display: flex;
            align-items: center;

            .location-initials {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: #4a66e5;
                color: white;
                font-size: 18px;
                flex-shrink: 0;
            }

            .location-name {
                color: rgb(44 41 75);
                font-weight: 500;
                padding: 0 16px;
            }
        }
    }

    .locations-list .location:last-child {
        border-style: none;
    }
}
</style>
