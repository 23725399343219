import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    methods: {
        showSuccessToast(message, hideClose = true) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                    hideClose: true
                },
            });
        },
        showErrorToast(message, hideClose = true) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message,
                    icon: 'XIcon',
                    variant: 'danger',
                    hideClose: true
                },
            });
        },
    }
}
